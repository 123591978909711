








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HomeTitle extends Vue {
  @Prop() public readonly title!: string;
  @Prop() public readonly description!: string;
  @Prop() public readonly light!: boolean;
}
