<template>
    <div class="home">
      <div id="main-top">
        <div id="main-top-block">
          <div id="main-top-block-background">
            <DoroImage id="main-top-block-background-image" :src="TopBackground" square simple />
          </div>
          <div id="main-top-block-text">
            <h1>We Empower <br />Businesses To Scale.</h1>

            <div class="main-top-block-text-description">
              AI REV is a boutique partnership of AI experts who are highly skilled in transforming your ideas into AI-based solutions to give you
                a competitive edge.
            </div>

            <router-link to="/contact-us" id="main-top-book-ai"><DoroButton>SCHEDULE A MEETING</DoroButton></router-link>

            <div class="main-top-scroll-down" @click="scrollToMainMore">
              <div class="main-top-scroll-down-text">Scroll Down</div>
              <div class="main-top-scroll-down-arrow">
                <DoroImage :src="ScrollDownArrow" square simple />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="main-limiter-2" style="padding:45px 0 92px 0" id="main-page-more">
          <div style="text-align:center;margin-bottom: 35px;">
            Our partners have gained experience working with companies such as
          </div>
          <div class="featured-in">
            <carousel
                :scroll-per-page="false"
                :perPageCustom="[[0, 1], [480, 2], [768, 3], [1024, 4], [1200, 5]]"
                :navigation-enabled="true"
                :pagination-enabled="false"
                :autoplay="true"
                :autoplay-timeout="3500"
                :loop="true"
                navigation-next-label="→"
                navigation-prev-label="←"
                :navigation-click-target-size="0"
                :center-mode="true"
                class="featured-in-carousel"
            >
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoCern" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoNasa" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoDarpa" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoNvidia" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoMicrosoft" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoNovartis" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoOracle" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoOpera" square simple max-height="100px" :spread-vertically="true" maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoWenet" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="IconSinobest" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoSDI" square simple maxWidth="200px" />
                </div>
              </slide>
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="IconQuovant" square simple max-height="100px" :spread-vertically="true" maxWidth="200px" />
                </div>
              </slide>
<!--              <slide>-->
<!--                <div class="featured-in-item">-->
<!--                  <DoroImage :src="Eastspring" square simple maxWidth="200px" />-->
<!--                </div>-->
<!--              </slide>-->
              <slide>
                <div class="featured-in-item">
                  <DoroImage :src="LogoKroll" square simple maxWidth="200px" />
                </div>
              </slide>
<!--              <slide>-->
<!--                <div class="featured-in-item">-->
<!--                  <DoroImage :src="LogoAtomwise" square simple max-height="100px" :spread-vertically="true" maxWidth="200px" />-->
<!--                </div>-->
<!--              </slide>-->
            </carousel>
          </div>
        </div>
      </div>

      <div class="section-blue-bg">
        <HomeTitle
            title="Bring the Power of AI to Your Company"
            description="The accomplished team of AI experts are to design the best AI solutions for your company."
            :light="true"
        />
        <div class="main-limiter-2" style="padding:80px 0 31px">
          <el-row :gutter="30">
            <el-col :sm="8" :xs="24">
              <div class="ai-power-box">
                <div class="ai-power-box-inside">
                  <div class="ai-power-box-icon">
                    <DoroImage :src="IconIdea" square simple />
                  </div>
                  <div class="ai-power-box-inside-title">
                    Unlock AI’s <br />
                    Full Potential
                  </div>
                  <div class="ai-power-box-inside-description">
                    AI REV’s team identifies which scenarios hold the greatest optimization capability for your company to leverage the power of AI to its full potential.
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24">
              <div class="ai-power-box">
                <div class="ai-power-box-inside">
                  <div class="ai-power-box-icon">
                    <DoroImage :src="IconHearthHands" square simple style="padding: 3px 0 0 1px;" />
                  </div>
                  <div class="ai-power-box-inside-title">
                    Scale Faster <br />
                    With AI
                  </div>
                  <div class="ai-power-box-inside-description">
                    Our AI solutions save up 25% on departmental costs, substantially decreasing operational risk and enabling manpower reallocation to more strategic issues.
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="8" :xs="24">
              <div class="ai-power-box">
                <div class="ai-power-box-inside">
                  <div class="ai-power-box-icon">
                    <DoroImage :src="IconHighRoi" square simple />
                  </div>
                  <div class="ai-power-box-inside-title">
                    Enjoy High <br />
                    Return on Investment
                  </div>
                  <div class="ai-power-box-inside-description">
                    AI REV designs AI solutions that deliver at least 10x ROI within 7 years after deployment.
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="section-white-bg">
        <HomeTitle
            title="AI wherever you need it"
            description="Using our department-specific experience,  we deliver AI solutions tailored to your unique needs."
        />
        <div class="main-limiter-2" style="padding:50px 0 50px">
          <el-row :gutter="25">
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconSales" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 20px;">
                    Sales
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 20px;">
                    Automatically generate new leads, predict your future sales, demand, and churn of your customers
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconMarketing" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 20px;">
                    Marketing
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 20px;">
                    Optimize your budget spending, predict your customers’ behaviour, and drive up your sales using personalization
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconFinanse" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 20px;">
                    Finances
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 20px;">
                    Mitigate the risks, swiftly & thoroughly analyze the market and your competition using Deep Learning
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconAdministration" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 20px;">
                    Administration
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 20px;">
                    Help your employees with automatic document processing & classification, reporting, and data procesing
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconCustomerService" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 20px;">
                    Customer Service
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 20px;">
                    Enhance your services with conversational AI, chatbots, automatic customer processing and AI assistants
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconHumanResources" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 20px;">
                    Human Resources
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 20px;">
                    Mitigate employee rotation, automatically parse applications, find candidates using AI, and receive actionable insights from your HR-related data
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="call-to-action" style="padding-bottom:26px;">
          <div class="cta-side cta-left">
            &nbsp;
          </div>
          <div class="cta-main">
            <div class="cta-main-inside">
              <div class="cta-main-title">
                AI doesn’t have to be complicated
              </div>
              <div class="cta-main-description">
                Let the experts solve your problems
              </div>
            </div>
            <div class="cta-main-button" @click="$router.push('/contact-us')">
              Let's talk
              <DoroImage :src="IconArrowRight" square simple style="float: right;"/>
            </div>
          </div>
          <div class="cta-side">
            &nbsp;
          </div>
        </div>
      </div>

      <div class="section-dark-bg">
        <div class="case-study">
          <div class="case-study-inside">
            <div class="case-study-bg-left">
              <DoroImage :src="CaseStudyBGLeft" square simple />
            </div>
            <div class="case-study-content">
              <div class="case-study-line"></div>
              <div class="case-study-title">
                Case Study
              </div>
              <carousel
                  :scroll-per-page="true"
                  :per-page="1"
                  :navigation-enabled="true"
                  :pagination-enabled="false"
                  :autoplay="true"
                  :autoplay-timeout="3500"
                  :loop="true"
                  navigation-next-label="→"
                  navigation-prev-label="←"
                  :navigation-click-target-size="0"
              >
                <slide>
                  <div class="case-study-description">
                    Invoice Automatic Classification
                  </div>

                  <div class="case-study-bottom-content">
                    <div class="case-study-icon">
                      <DoroImage :src="IconBill" square simple />
                    </div>
                    <div class="case-study-long-desc">
                      <div class="case-study-long-desc-title">
                        Client
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        A major US enterprise with over 20 years of experience in helping companies from the Fortune 100 in legal compliance and smart costs management.
                      </div>
                      <div class="case-study-long-desc-title">
                        Solution
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        We developed a custom-built AI classification system that surpasses human accuracy, achieving a remarkable 98.5% precision. The solution was not only provided, but also complemented with an intuitive administrative interface that offers robust analytics, granular datasets management, and flexible configuration options.
                      </div>
                      <!--                  <div class="case-study-button"-->
                    </div>
                  </div>
                </slide>
                <slide>
                  <div class="case-study-description">
                    Parkison’s disease diagnosis
                  </div>

                  <div class="case-study-bottom-content">
                    <div class="case-study-icon">
                      <DoroImage :src="IconBill" square simple />
                    </div>
                    <div class="case-study-long-desc">
                      <div class="case-study-long-desc-title">
                        Client
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        A VC-backed neuroscience company working to redefine neurology care and drug development was seeking a way to improve Parkinson’s disease diagnosis.
                      </div>
                      <div class="case-study-long-desc-title">
                        Solution
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        Utilizing state-of-the-art computer vision algorithms, we have developed a method for detecting early stage Parkinson's disease through analysis of pupillary response. By leveraging artificial intelligence, our algorithm is able to identify subtle changes that may not be immediately apparent to physicians, ultimately leading to more accurate diagnosis compared to traditional methods. This innovative approach requires only a smartphone, making it a convenient and cost-effective solution for healthcare professionals.
                      </div>
                      <!--                  <div class="case-study-button"-->
                    </div>
                  </div>
                </slide>
                <slide>
                  <div class="case-study-description">
                    Generating calls with investors
                  </div>

                  <div class="case-study-bottom-content">
                    <div class="case-study-icon">
                      <DoroImage :src="IconBill" square simple />
                    </div>
                    <div class="case-study-long-desc">
                      <div class="case-study-long-desc-title">
                        Client
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        A biopharma holding which builds value through an innovative clinical development process focused on searching for investors globally.
                      </div>
                      <div class="case-study-long-desc-title">
                        Solution
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        Through the implementation of a sophisticated AI-driven lead generation strategy, the company was able to establish connections with a diverse array of investors globally. The utilization of a tailored approach to communication and a precisely defined target demographic resulted in a successful campaign, culminating in 51 productive meetings with potential investors within a three-month period.
                      </div>
                      <!--                  <div class="case-study-button"-->
                    </div>
                  </div>
                </slide>
                <slide>
                  <div class="case-study-description">
                    Insurance agents activization
                  </div>

                  <div class="case-study-bottom-content">
                    <div class="case-study-icon">
                      <DoroImage :src="IconBill" square simple />
                    </div>
                    <div class="case-study-long-desc">
                      <div class="case-study-long-desc-title">
                        Client
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        A US-based insurance company focused on optimizing the sales activities of its brokers, simplifying the sale of the company's insurance products and maximizing the total value of their sales.
                      </div>
                      <div class="case-study-long-desc-title">
                        Solution
                      </div>
                      <div class="case-study-long-desc-description" style="padding-bottom: 45px;">
                        Data-driven analysis was implemented. Collecting and analyzing data on brokers from several sources allowed AI to divide them into psychological profiles. Then, a correlation between psychological profiles and insurance products sold was detected (e.g. extroverts sell life insurance best). Linking brokers to the insurance inquiry best suited to their personality turned out to be a huge success. The solution increased the value of sales by +18% YOY and it required no interference in the company's organizational structure nor the broker’s team itself.
                      </div>
                      <!--                  <div class="case-study-button"-->
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
            <div class="case-study-bg-right">
              <DoroImage :src="HumanLines" square simple max-height="567px" :spread-vertically="true" />
            </div>
          </div>
        </div>
      </div>

      <div class="section-white-bg">
        <HomeTitle
            title="AI REV’s Expertise"
            description="AI REV’s team of experts continually delivers commercial projects for, i.a. Fortune 500 companies, in the following fields:"
        />

        <div class="main-limiter-2" style="padding:50px 0 50px">
          <el-row :gutter="25">
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconMachineLearning" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 10px;">
                    Machine Learning
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 10px;">
                    Using supervised, unsupervised, and reinforcement machine learning, neural networks, and deep learning, we build AI systems that effectively relieve companies from resourceful processes.
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconPredictiveAnalytics" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 10px;">
                    Predictive Analytics
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 10px;">
                    We create smart systems that automatically give actionable insights to improve ROI on business processes.
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconNLP2" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 10px;">
                    Natural Language Processing
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 10px;">
                    Process large amounts of text and documents within seconds using our algorithms. Understand sentiment and context to extract the most important information automatically.
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :sm="12" :xs="24">
              <div class="grey-card">
                <div class="grey-card-inside">
                  <div class="grey-card-icon">
                    <DoroImage :src="IconComputerVision" square simple />
                  </div>
                  <div class="grey-card-inside-title" style="padding-top: 10px;">
                    Computer Vision
                  </div>
                  <div class="grey-card-inside-description" style="padding-top: 10px;">
                    Thanks to real-time sophisticated visual data analysis, we enable the accurate recognition and extraction of information or categorization of objects.
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="section-blue-bg">
        <HomeTitle
            title="One step at a time"
            description="The implementation of AI in the company does not have to be difficult. We will guide you through this process step by step."
            :light="true"
        />

        <div class="main-limiter-2" style="padding:50px 0">
          <div class="one-stop-flex">
            <div class="osf-item">
              <div class="osf-item-number">
                01
              </div>
              <div class="osf-item-content">
                <div class="osf-item-title">
                  COMPLIMENTARY AI CONSULTATION
                </div>
                <div class="osf-item-description">
                  Introductory consultation to discover business needs and an initial identification of where AI should be applied within the company and what benefits can be brought to fruition.
                </div>
              </div>
            </div>
            <div class="osf-item">
              <div class="osf-item-number">
                02
              </div>
              <div class="osf-item-content">
                <div class="osf-item-title">
                  STRATEGY WORKSHOPS
                </div>
                <div class="osf-item-description">
                  Detailed identification of business needs, interviews with employees and/or clients, technical and business research, and initial design of an optimal AI solution.
                </div>
              </div>
            </div>
            <div class="osf-item">
              <div class="osf-item-number">
                03
              </div>
              <div class="osf-item-content">
                <div class="osf-item-title">
                  AI PROOF-OF-CONCEPT
                </div>
                <div class="osf-item-description">
                  Implementation of PoC project to show all AI possibilities and prove applicability with benefits for a company.
                </div>
              </div>
            </div>
          </div>
          <div class="one-stop-flex" style="padding-top:81px;justify-content: space-evenly">
            <div class="osf-item" style="width: calc(33% - 10px)">
              <div class="osf-item-number">
                04
              </div>
              <div class="osf-item-content">
                <div class="osf-item-title">
                  AI SYSTEM IMPLEMENTATION
                </div>
                <div class="osf-item-description">
                  Agile end2end project that demonstrates significant results for the client’s operations.
                </div>
              </div>
            </div>
            <div class="osf-item" style="width: calc(33% - 10px)">
              <div class="osf-item-number">
                05
              </div>
              <div class="osf-item-content">
                <div class="osf-item-title">
                  MAINTENANCE
                </div>
                <div class="osf-item-description">
                  Taking care of the proper functioning of the system and its current updating.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-white-bg">
        <HomeTitle
            title="What our Clients Say"
            description="See testimonial from various satisfied clients that worked with our partners."
        />

        <div class="main-limiter-2" style="padding:50px 0 50px">
          <carousel
              :perPageCustom="[[0, 1], [480, 1], [768, 2], [1024, 2], [1200, 2]]"
              :scroll-per-page="false"
              :navigation-enabled="true"
              :pagination-enabled="false"
              :autoplay="true"
              :autoplay-timeout="3500"
              :loop="true"
              navigation-next-label="→"
              navigation-prev-label="←"
              :navigation-click-target-size="0"
          >
            <slide>
              <div class="grey-card">
                <div class="grey-card-client-inside">
                  <div class="grey-card-client-block">
                    <div class="grey-card-client-icon">
                      <DoroImage :src="IconHarvard" square simple />
                    </div>
                    <div class="grey-card-client-title">
                      Harvard University
                    </div>
                    <div class="grey-card-client-title-description">
                      &nbsp;
                    </div>
                  </div>
                  <div class="grey-card-client-description">
                    We offer the highest recommendations for Mark Bardonski (Managing Partner at AI REV) without reservation. Mark Bardonski’s algorithmic skills will be a valuable asset to any organization looking for analytic and computational talent.
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="grey-card">
                <div class="grey-card-client-inside">
                  <div class="grey-card-client-block">
                    <div class="grey-card-client-icon">
                      <DoroImage :src="LogoNasa" simple maxHeight="78px" />
                    </div>
                    <div class="grey-card-client-title">
                      NASA
                    </div>
                    <div class="grey-card-client-title-description">
                      National Aeronautics and Space Administration
                    </div>
                  </div>
                  <div class="grey-card-client-description">
                    This is an enthusiastic recommendation in support of Mark Bardonski’s (Managing Partner at AI REV) outstanding accomplishments.
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="grey-card">
                <div class="grey-card-client-inside">
                  <div class="grey-card-client-block">
                    <div class="grey-card-client-icon">
                      <DoroImage :src="IconSinobest" square simple />
                    </div>
                    <div class="grey-card-client-title">
                      Sinobest
                    </div>
                    <div class="grey-card-client-title-description">
                      Energetic and expanding company from Hong Kong
                    </div>
                  </div>
                  <div class="grey-card-client-description">
                    The AI system developed by AI REV works well and reduces lots of laborious manpower. The project was conducted with great success. We believe if any company wishes to take advantage of Artificial Intelligence for their business, AI REV LLC is a good choice for resolving your problem.
                  </div>
                </div>
              </div>
            </slide>
            <slide>
              <div class="grey-card">
                <div class="grey-card-client-inside">
                  <div class="grey-card-client-block">
                    <div class="grey-card-client-icon">
                      <DoroImage :src="IconNextron" square simple maxHeight="78px" />
                    </div>
                    <div class="grey-card-client-title">
                      NX Electronics Limited
                    </div>
                    <div class="grey-card-client-title-description">
                      Trading company focused on memory products
                    </div>
                  </div>
                  <div class="grey-card-client-description">
                    During collaboration we found that AI REV LLC is knowledgeable and experienced in their area. They understand our requirements well and are able to offer advice right to the point. They are responsive to our questions and queries.
                  </div>
                </div>
              </div>
            </slide>
          </carousel>
        </div>

        <div class="call-to-action" style="padding-bottom:26px;">
          <div class="cta-side cta-left">
            &nbsp;
          </div>
          <div class="cta-main">
            <div class="cta-main-inside">
              <div class="cta-main-title">
                Curious about AI implementation?
              </div>
              <div class="cta-main-description">
                Discover more with our experts
              </div>
            </div>
            <div class="cta-main-button" @click="$router.push('/contact-us')">
              Let's talk
              <DoroImage :src="IconArrowRight" square simple style="float: right;"/>
            </div>
          </div>
          <div class="cta-side">
            &nbsp;
          </div>
        </div>
      </div>

      <div class="section-dark-bg">
        <HomeTitle
            title="Delivery at global scale"
            description="We work with companies across the globe."
            :light="true"
        />

        <div class="main-limiter-2" style="padding:50px 0">
          <div class="world-map">
            <div class="wmap-img">
              <DoroImage :src="WorldMap" square simple :pixelated="true" />
            </div>
            <div class="wmap-desc">
              <div class="wmap-desc-stat">
                <div class="wmap-desc-stat-icon">
                  <DoroImage :src="IconMapOffice" square simple maxWidth="45px" />
                </div>
                <div class="wmap-desc-stat-text">
                  <div>
<!--                    <div class="wmap-desc-stat-title">-->
<!--                      5-->
<!--                    </div>-->
                    <div class="wmap-desc-stat-description">
                      Core R&D offices
                    </div>
                  </div>
                </div>
              </div>
              <div class="wmap-desc-stat">
                <div class="wmap-desc-stat-icon">
                  <DoroImage :src="IconMapClients" square simple maxWidth="30px" />
                </div>
                <div class="wmap-desc-stat-text">
                  <div>
<!--                    <div class="wmap-desc-stat-title">-->
<!--                      100+-->
<!--                    </div>-->
                    <div class="wmap-desc-stat-description">
                      Official presence
                    </div>
                  </div>
                </div>
              </div>
              <div class="wmap-desc-stat">
                <div class="wmap-desc-stat-icon">
                  <DoroImage :src="IconMapEmployees" square simple maxWidth="45px" />
                </div>
                <div class="wmap-desc-stat-text">
                  <div>
<!--                    <div class="wmap-desc-stat-title">-->
<!--                      50+-->
<!--                    </div>-->
                    <div class="wmap-desc-stat-description">
                      Engineering teams
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="wmap-desc-stat">-->
<!--                <div class="wmap-desc-stat-title">-->
<!--                  10+-->
<!--                </div>-->
<!--                <div class="wmap-desc-stat-description">-->
<!--                  Enterprise-Grade-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>

      <div class="section-white-bg">
        <HomeTitle
            title="Featured In"
            description="Our Partners were featured in"
        />
      </div>

      <div class="main-limiter-2" style="padding:0 0 100px">
        <div class="featured-in">
          <a class="featured-in-item" href="https://www.bloomberg.com/professional/blog/ai-solving-banks-expensive-research-problem/" target="_blank">
            <DoroImage :src="LogoBloomberg" square simple />
          </a>
          <a class="featured-in-item" href="https://www.ft.com/content/e2645d5e-d67e-11e7-8c9a-d9c0a5c8d5c9" target="_blank">
            <DoroImage :src="LogoFinancialTimes" square simple />
          </a>
          <a class="featured-in-item" href="https://www.rocketmatter.com/blog/impact-artificial-intelligence-law-firms/" target="_blank">
            <DoroImage :src="LogoRocketMatter" square simple />
          </a>
          <a class="featured-in-item" href="https://www.twice.com/industry/ces-2018-what-are-smart-cities-and-why-should-we-care" target="_blank">
            <DoroImage :src="LogoTwice" square simple />
          </a>
        </div>
      </div>

    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';

    import { Carousel, Slide } from '@jambonn/vue-concise-carousel';
    import '@jambonn/vue-concise-carousel/dist/vue-concise-carousel.css'

    import ArticleList from '@/common/ArticleList';

    import DoroImage from '@/components/DoroImage';
    import HomeTitle from '@/components/HomeTitle';
    import DoroButton from '@/components/DoroButton';
    import URL from '@/components/URL';

    import TopBackground from '@/assets/graphics/brain.jpg';

    import PeopleMarek from '@/assets/people/Marek.jpg';
    import ImageRoboExplain from '@/assets/graphics/roboexplain.jpg';

    import LogoCern from '@/assets/logos/cern-simple.png';
    import LogoDarpa from '@/assets/logos/darpa-simple.png';
    import LogoLtCapital from '@/assets/logos/ltcapital.jpg';
    import LogoNasa from '@/assets/logos/nasa-simple.png';
    import LogoNovartis from '@/assets/logos/novartis-simple.png';
    import LogoNvidia from '@/assets/logos/nvidia-simple.png';
    import LogoOpera from '@/assets/logos/opera.jpg';

    import LogoAtomwise from '@/assets/logos/atomwise.png';
    import LogoKroll from '@/assets/logos/kroll.png';
    import LogoMicrosoft from '@/assets/logos/microsoft.png';
    import LogoOracle from '@/assets/logos/oracle.png';
    import LogoSDI from '@/assets/logos/sdi.png';
    import LogoWenet from '@/assets/logos/wenet.png';
    import Eastspring from '@/assets/logos/eastspring.png';

    import LogoFinancialTimes from '@/assets/pages/home/FinancialTimes.jpg';
    import LogoBloomberg from '@/assets/pages/home/Bloomberg.jpg';
    import LogoRocketMatter from '@/assets/pages/home/rocketmatter.jpg';
    import LogoTwice from '@/assets/pages/home/twice.jpg';

    import IconNLP from '@/assets/pages/home/iconnlp.png';
    import IconAnalytics from '@/assets/pages/home/iconanalytics.png';

    import IconAdministration from '@/assets/icons/administration.svg';
    import IconComputerVision from '@/assets/icons/computervision.svg';
    import IconCustomerService from '@/assets/icons/customerservice.svg';
    import IconFinanse from '@/assets/icons/finanse.svg';
    import IconHearthHands from '@/assets/icons/hearthhands.svg';
    import IconHighRoi from '@/assets/icons/highroi.svg';
    import IconHumanResources from '@/assets/icons/humanresources.svg';
    import IconIdea from '@/assets/icons/idea.svg';
    import IconMachineLearning from '@/assets/icons/machinelearning.svg';
    import IconMarketing from '@/assets/icons/marketing.svg';
    import IconNLP2 from '@/assets/icons/nlp.svg';
    import IconPredictiveAnalytics from '@/assets/icons/predictive.svg';
    import IconSales from '@/assets/icons/sales.svg';

    import IconArrowRight from '@/assets/icons/arrowright.svg';

    import IconSinobest from '@/assets/logos/sinobest.png';
    import IconQuovant from '@/assets/logos/quovant-icon.png';
    import IconHarvard from '@/assets/logos/harvard-logo.png';
    import IconNextron from '@/assets/logos/nextron.png';

    import WorldMap from '@/assets/graphics/mapka-min.svg';

    import CaseStudyBGLeft from '@/assets/graphics/case-study-bg-left.svg';
    import HumanLines from '@/assets/graphics/humanlines.png';

    import IconBill from '@/assets/graphics/bill.png';
    import ScrollDownArrow from '@/assets/icons/scrolldown.svg';

    import IconMapOffice from '@/assets/graphics/mapiconoffice.png';
    import IconMapEmployees from '@/assets/graphics/mapiconemployees.png';
    import IconMapClients from '@/assets/graphics/mapiconclients.png';

    @Component({
        components: {
          Carousel,
          Slide,
          ArticleList,
          DoroImage,
          DoroButton,
          URL,
          HomeTitle,
        },
        metaInfo() {
            return {
                titleTemplate: 'AI REV - a boutique AI consulting company'
            }
        }
    })
    export default class Home extends Vue {
        get TopBackground() { return TopBackground; }

        get PeopleMarek() { return PeopleMarek; }
        get ImageRoboExplain() { return ImageRoboExplain; }

        get LogoCern() { return LogoCern; }
        get LogoDarpa() { return LogoDarpa; }
        get LogoLtCapital() { return LogoLtCapital; }
        get LogoNasa() { return LogoNasa; }
        get LogoNovartis() { return LogoNovartis; }
        get LogoNvidia() { return LogoNvidia; }
        get LogoOpera() { return LogoOpera; }

        get LogoAtomwise() { return LogoAtomwise; }
        get LogoKroll() { return LogoKroll; }
        get LogoMicrosoft() { return LogoMicrosoft; }
        get LogoOracle() { return LogoOracle; }
        get LogoSDI() { return LogoSDI; }
        get LogoWenet() { return LogoWenet; }
        get Eastspring() { return Eastspring; }

        get LogoFinancialTimes() { return LogoFinancialTimes; }
        get LogoBloomberg() { return LogoBloomberg; }
        get LogoRocketMatter() { return LogoRocketMatter; }
        get LogoTwice() { return LogoTwice; }

        get IconNLP() { return IconNLP; }
        get IconAnalytics() { return IconAnalytics; }

        get IconAdministration () { return IconAdministration; }
        get IconComputerVision () { return IconComputerVision; }
        get IconCustomerService () { return IconCustomerService; }
        get IconFinanse () { return IconFinanse; }
        get IconHearthHands () { return IconHearthHands; }
        get IconHighRoi () { return IconHighRoi; }
        get IconHumanResources () { return IconHumanResources; }
        get IconIdea () { return IconIdea; }
        get IconMachineLearning () { return IconMachineLearning; }
        get IconMarketing () { return IconMarketing; }
        get IconNLP2 () { return IconNLP2; }
        get IconPredictiveAnalytics () { return IconPredictiveAnalytics; }
        get IconSales () { return IconSales; }

        get IconArrowRight () { return IconArrowRight; }

        get IconSinobest () { return IconSinobest; }
        get IconQuovant () { return IconQuovant; }
        get IconHarvard () { return IconHarvard; }
        get IconNextron () { return IconNextron; }

        get WorldMap () { return WorldMap; }

        get CaseStudyBGLeft () { return CaseStudyBGLeft; }
        get HumanLines () { return HumanLines; }

        get IconBill () { return IconBill; }

        get ScrollDownArrow () { return ScrollDownArrow; }

        get IconMapOffice () { return IconMapOffice; }
        get IconMapEmployees () { return IconMapEmployees; }
        get IconMapClients () { return IconMapClients; }

        scrollToMainMore() {
            const mainMore = document.getElementById('main-page-more');
            if (mainMore) {
                mainMore.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
</script>

<style lang="scss" scoped>
    $Max-Page-Width: 1050px;
    $Max-Page-Width-2: 1150px;

    .home {
        h1 {
            text-align: left;
        }

        .del1-left {
            max-width: 60%;
        }
        .del1-right {
            float:right;
            max-width: 40%;
            margin-top: 2em;

            & > div {
                padding: 5px;
            }
        }
        @media only screen and (max-width: 940px) {
            .del1-left {
                max-width: 100%;
            }
            .del1-right {
                display: none;
            }
        }

      #main-top {
        background: black;
        min-height: 570px;

        #main-top-block {
          min-height: 570px;
          max-width: $Max-Page-Width-2;
          margin: 0 auto;
          color: white;
          display:block;
          position:relative;
          z-index: 0;

          #main-top-block-background {
            position: absolute;
            top: 0;
            bottom: 0;
            //left: 60px;
            //right: -60px;
            z-index: -1;
            height: 100%;

            transition: opacity 0.3s;

            #main-top-block-background-image {
              padding: 0;
              margin: 0;
              height: 100%;
              z-index: -2;

              img {
                height: 100%;
                max-width: unset;
                opacity: 0.85;
              }
            }

            @media only screen and (max-width: 600px) {
              opacity: 0;
            }
          }

          #main-top-block-text {
            //padding: 5em 5px;
            padding: 110px 5px 35px;
            max-width: 600px;

            h1 {
              margin:0;
              color:white;
              line-height: 70px;
              font-size: 60px;
              font-weight: 400;

              //margin-bottom: 36px;
              margin-bottom: 40px;

              transition: font-size 0.3s, line-height 0.3s;

              @media only screen and (max-width: 600px) {
                font-size: 40px;
                line-height: 50px;
              }
            }

            .main-top-block-text-description {
              max-width: 500px;
              color: #ccc;
              font-size: 18px;
              line-height: 26px;
              font-weight: 500;

              //margin-bottom: 36px;
              //margin-bottom: 44px;
              margin-bottom: 49px;
            }

            #main-top-book-ai .dorobutton {
              background: #17861E;
              color: white;

              border-radius: 50px;
              padding: 19px;
              margin-left: 8px;
              font-weight: 700;

              .btn-text {
                font-size: 16px;

                text-transform: uppercase;

                padding: 0 10px;
              }
            }

            .main-top-scroll-down {
              height: 26px;
              display: flex;
              margin-top: 100px;
              cursor: pointer;
              width: 121px;

              .main-top-scroll-down-text {
                display: inline-block;
                font-size: 16px;
                line-height: 26px;
                font-weight: 500;
                color: #c4c4c4;
                padding-right: 4px;
              }

              .main-top-scroll-down-arrow {
                display: inline-block;
                height: 26px;
              }
            }
          }
        }
      }

      .section-blue-bg {
        background: #07437F;
        color: white;
        padding: 49px 0;
        text-align: center;
      }

      .section-white-bg {
        background: #FFF;
        color: white;
        padding: 49px 0;
        text-align: center;
      }

      .section-dark-bg {
        background: #00121E;
        color: white;
        padding: 49px 0;
        text-align: center;
      }

      .ai-power-box {
        min-height: 345px;
        width: 100%;
        border: 0.5px solid rgba(255, 255, 255, 0.5);
        border-radius: 10px;

        .ai-power-box-inside {
          padding: 34px 32px;

          .ai-power-box-icon {
            background: #FFF;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .ai-power-box-inside-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: white;
            margin: 25px 0;
          }

          .ai-power-box-inside-description {
            font-weight: 300;
            font-size: 15px;
            line-height: 24px;
            color: #ffffff;

          }
        }
      }

      .grey-card {
        background: rgba(24, 83, 123, 0.04);
        border-radius: 19px;
        min-height: 180px;
        margin-bottom: 25px;
        margin-left: 10px;
        margin-right: 10px;

        .grey-card-inside {
          padding: 19px;

          .grey-card-icon {
            background: #FFF;
            width: 66px;
            height: 66px;
            border-radius: 50%;
            float: left;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .grey-card-inside-title {
            color: #1A1A1A;
            padding-left: 85px;
            font-weight: 700;
            font-size: 22px;
            line-height: 24px;
            text-align: left;
          }

          .grey-card-inside-description {
            color: #707070;
            padding-left: 85px;
            padding-right: 20px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
          }
        }

        .grey-card-client-inside {
          padding: 35px;
          min-height: 242px;

          .grey-card-client-block {
            display: block;
            min-height: 52px;

            .grey-card-client-icon {
              width: 82px;
              height: 78px;
              float: left;
              text-align: center;
              align-items: center;
              justify-content: center;
            }

            .grey-card-client-title {
              color: #1A1A1A;
              padding-left: 100px;
              font-weight: 700;
              font-size: 24px;
              line-height: 28px;
              text-align: left;
            }

            .grey-card-client-title-description {
              color: #777777;
              padding-left: 100px;
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              text-align: left;
            }
          }

          .grey-card-client-description {
            color: #263238;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: left;
            padding-top: 40px;
          }
        }
      }

      .call-to-action {
        display: flex;
        width: 100%;
        flex-flow: row;
        text-align: left;

        .cta-side {
          flex-grow: 1;
        }

        .cta-main {
          width: 100%;
          max-width: $Max-Page-Width-2;
          display: flex;

          .cta-main-inside {
            flex-grow: 1;
            padding: 18px 0;
            background: #18871E;

            .cta-main-title {
              color: white;
              font-weight: 700;
              font-size: 24px;
              line-height: 30px;
              text-align: left;
              padding: 0 20px 5px;
            }

            .cta-main-description {
              color: white;
              font-weight: 500;
              font-size: 16px;
              line-height: 26px;
              text-align: left;
              padding: 0 20px;
            }
          }

          .cta-main-button {
            width: 314px;
            float: right;
            background: #115F15;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            font-size: 36px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
          }
        }

        .cta-left {
          background: #18871E;
        }
      }

      .one-stop-flex {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        text-align: left;
        justify-content: space-between;
        row-gap: 81px;

        .osf-item {
          width: calc(33% - 40px);
          min-height: 131px;
          //background: rgba(255, 255, 255, 0.1);
          display: flex;
          //align-items: center;


          .osf-item-number {
            width: 50px;
            font-size: 35px;
            font-weight: 700;
            color: #1A8720;
            padding-top: 34px;
          }

          .osf-item-content {
            padding-left: 20px;

            .osf-item-title {
              font-size: 22px;
              font-weight: 600;
              line-height: 22px;
              padding-bottom: 9px;
            }

            .osf-item-description {
              font-size: 15px;
              font-weight: 300;
              line-height: 22px;
              text-align: justify;
              color: #ffffff;
            }
          }
        }
      }

      .world-map {
        display: flex;
        column-gap: 20px;

        .wmap-img {
          flex-grow: 1;
        }

        .wmap-desc {
          //padding-top: 20px;
          width: 405px;
          display: flex;
          flex-flow: column;
          row-gap: 40px;
          justify-content: center;

          .wmap-desc-stat {
            display: flex;
            flex-flow: row;
            column-gap: 10px;
            justify-content: start;

            .wmap-desc-stat-icon {
              width: 50px;
              justify-content: center;
              align-items: center;
              display: flex;
            }

            .wmap-desc-stat-text {
              flex-grow: 1;
              //justify-content: center;
              justify-content: start;
              align-items: center;
              display: flex;

              .wmap-desc-stat-title {
                font-size: 32px;
                line-height: 42px;
                font-weight: 700;
                color: #1A8720;
              }

              .wmap-desc-stat-description {
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                color: white;
                text-align: left;
              }
            }
          }
        }
      }

      .featured-in {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 80px;

        .featured-in-item {
          text-decoration: none;
          filter: grayscale(100%);
          transition: filter 0.3s;

          display: flex;
          align-items: center;
          justify-content: center;

          //&:hover {
          //  filter: grayscale(0%);
          //}
        }

        .featured-in-item-hidden-big {
          display: none;
        }
      }
    }

    .case-study {
      .case-study-inside {
        max-width: $Max-Page-Width-2;
        margin: 0 auto;
        position: relative;
        min-height: 442px;
        display: flex;

        .case-study-bg-left {
          position: absolute;
          left: -422px;
          top: 0;
          height: 442px;
          opacity: 0.9;
        }

        .case-study-content {
          display: inline-block;
          width: 100vw;
          max-width: 753px;
          text-align: left;
          margin-left: 40px;

          @media (max-width: 768px) {
            margin-left: 0;
            padding-right: 30px;
            width: calc(100vw - 30px);
          }

          .case-study-line {
            float: left;
            margin-top: 7px;
            margin-right: 25px;
            height: 60px;
            width: 5px;
            background: #1A8720;
          }

          .case-study-title {
            font-size: 28px;
            font-weight: 400;
            line-height: 32px;
          }

          .case-study-description {
            font-size: 36px;
            font-weight: 400;
            line-height: 42px;
          }

          .case-study-bottom-content {
            display: flex;
            padding-top: 81px;
            column-gap: 40px;
            max-width: 100%;

            .case-study-icon {
              flex-grow: 1;
              max-width: 100%;
              transition: max-width 0.3s, width 0.3s;
            }

            .case-study-long-desc {
              max-width: 440px;

              .case-study-long-desc-title {
                font-size: 24px;
                line-height: 26px;
                font-weight: 700;
              }

              .case-study-long-desc-description {
                font-size: 16px;
                line-height: 26px;
                font-weight: 500;
                text-align: justify;
                color: #D4D4D4;
              }
            }
          }
        }

        .case-study-bg-right {
          position: absolute;
          left: 768px;
          top: 14px;
          height: 567px;
          min-width: 700px;
          pointer-events: none;
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      .home {
        #main-top #main-top-block, .main-limiter-2 {
          padding-left: 20px !important;
          padding-right: 20px !important;
        }

        .world-map {
          flex-flow: column;
          align-items: center;
          gap: 40px;

          .wmap-img {
            width: 100%;
          }

          .wmap-desc {
            width: 100%;
            padding-top: 0;
            flex-flow: row;
            justify-content: space-around;
            gap: 40px;
          }
        }
      }
    }

    @media only screen and (max-width: 840px) {
      .home {
        .case-study-icon {
          max-width: calc(100% - 353px - 100px) !important;
        }

        .featured-in {
          flex-flow: row wrap;
          gap: 40px;
          .featured-in-item {
            width: calc(50% - 20px);
          }
          .featured-in-item-hidden-big {
            display: block;
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      .home {
        .ai-power-box {
          min-height: unset !important;
          margin-bottom: 40px !important;
        }

        .grey-card {
          min-height: unset !important;

          .grey-card-client-inside {
            min-height: unset !important;
          }
        }

        .one-stop-flex {
          flex-flow: column;
          gap: 40px;

          &:nth-child(2) {
            padding-top: 40px !important;
          }

          .osf-item {
            width: 100% !important;
            min-height: unset !important;
            margin-bottom: 0 !important;

            .osf-item-number {
              padding-top: 0 !important;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .home {
        .case-study-bottom-content {
          display: block !important;
          padding-top: 40px !important;

          .case-study-icon {
            max-width: 0 !important;
            display: none !important;
          }

          .case-study-long-desc {
            max-width: calc(100% - 40px) !important;
          }
        }

        .world-map {
          .wmap-desc {
            /* max 2 per row */
            flex-flow: row wrap;
            .wmap-desc-stat {
              width: calc(50% - 20px);
            }
          }
        }

        .cta-main-button {
          font-size: 26px !important;
        }

        //.featured-in {
        //  flex-flow: column;
        //  gap: 20px;
        //  .featured-in-item {
        //    width: 100%;
        //  }
        //}
      }
    }
</style>

<style lang="scss">
.VueCarousel-navigation-button {
  //background: #FFFFFF !important;
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  border: 1px solid #C4C4C4 !important;
  color: #ababab !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  text-align: center !important;

  &.VueCarousel-navigation-next {
    transform: translateX(80px) !important;
  }

  &.VueCarousel-navigation-prev {
    transform: translateX(-80px) !important;
  }
}

.featured-in-carousel {
  &.VueCarousel {
    max-width: 100% !important;
  }
  .VueCarousel-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .VueCarousel-inner {
    align-items: center;
  }
  .VueCarousel-navigation-button {
    top: 29px !important;
  }
}

@media only screen and (max-width: 1250px) {
  .VueCarousel-navigation {
    display: none !important;
  }
}
</style>
